import React, { useCallback, useMemo, useState } from "react"

import { Option } from "common/models/option"
import { withTranslation } from "common/utils/lang"

import { GroupCohort } from "main-app/models/cohort"
import { GroupSessionParticipant } from "main-app/models/participants"
import { AttendanceStatus, AttendanceStatuses } from "main-app/models/types"
import Avatar from "main-app/shared/avatar/Avatar"
import { GuestParticipantAvatar } from "main-app/shared/guest-participant-avatar/GuestParticipantAvatar"
import Select from "main-app/shared/select-default/Select"
import useRoles from "main-app/utils/hooks/use-roles"

import { AttendeeRadioButton } from "./AttendeeRadioButton"

interface IProps {
    participant: GroupSessionParticipant
    cohort: GroupCohort
    enrollmentRank: number
    error: boolean
    canApplyModules: boolean
    onModuleSelect: (assignedModule: any) => void
    onMarkAttendance: (participant: GroupSessionParticipant & { status: AttendanceStatus }) => void
}

const AttendeeRow: React.FC<IProps> = ({
    participant,
    cohort,
    onModuleSelect,
    onMarkAttendance,
    enrollmentRank,
    canApplyModules,
    error
}) => {
    const { isCoach, isProgramManager } = useRoles()

    const [marked, setMarked] = useState<{ checked: boolean; name: AttendanceStatus | "" }>({
        checked: !!participant.attendanceStatus,
        name: participant.attendanceStatus || ""
    })

    const options: Option[] = useMemo<Option[]>(
        () =>
            cohort?.modules?.map(
                module =>
                    new Option({
                        id: module.id,
                        label: withTranslation(module.version),
                        value: module.id,
                        disabled: module.enrolled
                    })
            ) ?? [],
        [cohort]
    )

    const optionsList = useMemo(() => {
        const sortedOptionsList = [...options].sort((a, b) => `${a.label}`.localeCompare(`${b.label}`))
        return [new Option({ id: "", label: "Assign Module", value: null, disabled: false }), ...sortedOptionsList]
    }, [options])

    const onSelect = useCallback(
        option => {
            onModuleSelect({
                module: option.value,
                participant: participant.id,
                cohort: cohort.id
            })
        },
        [cohort, participant, onModuleSelect]
    )

    const endOfJourneyReached = useMemo(
        () => enrollmentRank === participant.journeyLength,
        [enrollmentRank, participant]
    )

    const handleRadioButtonClick = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const {
                checked,
                dataset: { id }
            } = event.target

            setMarked({ checked, name: id as AttendanceStatus })
            onMarkAttendance({ ...participant, status: id as AttendanceStatus })
        },
        [onMarkAttendance]
    )

    const shouldSelectBeHiddenBasedOnEnrollmentRank: boolean = enrollmentRank === -1
    const currentModuleWithPlusOneHigherRank =
        participant?.enrolledModules?.find(m => m.rank === enrollmentRank + 1) ?? undefined
    const shouldSelectBeDisabled: boolean = !!currentModuleWithPlusOneHigherRank
    const optionIndex: number = (() => {
        if (currentModuleWithPlusOneHigherRank)
            return optionsList.findIndex(o => o.id === currentModuleWithPlusOneHigherRank.id)

        return 0
    })()

    return (
        <div className="row mt-3 align-items-center attendee-row">
            <div className="col-4">
                <div className="d-flex align-items-center">
                    <div>
                        <div className="flex-1">
                            {participant?.isGuest ? (
                                <GuestParticipantAvatar url={participant?.photo} />
                            ) : (
                                <Avatar url={participant?.photo} className="participant-image" />
                            )}
                        </div>
                    </div>

                    <span className="font-extrabold ml-2">
                        {participant?.firstName} {participant?.lastName}
                    </span>
                </div>
            </div>
            <div className="col-4">
                <div className="d-flex justify-content-center">
                    <AttendeeRadioButton
                        status={AttendanceStatuses.NOT_PRESENT}
                        className="mr-2"
                        marked={marked}
                        id={participant.id}
                        type="cross"
                        name="attendee"
                        handleRadioButtonClick={handleRadioButtonClick}
                    />
                    <AttendeeRadioButton
                        status={AttendanceStatuses.PRESENT}
                        marked={marked}
                        id={participant.id}
                        name="attendee"
                        type="check"
                        handleRadioButtonClick={handleRadioButtonClick}
                    />
                </div>
            </div>
            {shouldSelectBeHiddenBasedOnEnrollmentRank || (
                <div className="col-4">
                    {(isCoach || isProgramManager) && canApplyModules && (
                        <>
                            {marked.name === AttendanceStatuses.PRESENT && !endOfJourneyReached ? (
                                <Select
                                    backgroundWhite
                                    optionsList={optionsList}
                                    onSelect={onSelect}
                                    disabled={shouldSelectBeDisabled}
                                    defaultOptionIndex={optionIndex}
                                    withDisabledKeyboardNavigation
                                    error={error}
                                />
                            ) : (
                                (endOfJourneyReached || marked.name === AttendanceStatuses.NOT_PRESENT) && (
                                    <div className="text-center">
                                        <span className="color-gray">
                                            {endOfJourneyReached ? "End of journey reached!" : "Unavailable to assign"}
                                        </span>
                                    </div>
                                )
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

export default AttendeeRow
