import React, { type FC, type NamedExoticComponent, type ReactElement, memo } from "react"

import Heading from "common/components/Heading/Heading"
import { type IUseTranslation, useTranslation } from "common/hooks/use-translation"

const GuestParticipantHeadingComponent: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <Heading textAlign="center" tag="h1" className="swap-session__heading">
            {t("participantSide.swapSessionsScreen.title")}
        </Heading>
    )
}

const GuestParticipantHeading: NamedExoticComponent = memo(GuestParticipantHeadingComponent)

export { GuestParticipantHeading }
