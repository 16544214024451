import React from "react"

import classNames from "classnames"
import { Link, useLocation } from "react-router-dom"

import { CoachSettingsUrls } from "main-app/pages/coach-settings/constants"
import Sidebar from "main-app/shared/coach-sidebar/Sidebar"

const LeftSidebar = () => {
    const { pathname }: Location = useLocation()

    return (
        <Sidebar className="pt-0">
            <ul className="menu-capitalized">
                <li className={classNames({ active: pathname.includes(CoachSettingsUrls.AVAILABILITY) })}>
                    <Link to={CoachSettingsUrls.AVAILABILITY}>My Working Hours</Link>
                </li>
                <li className={classNames({ active: pathname.includes(CoachSettingsUrls.CALENDARS) })}>
                    <Link to={CoachSettingsUrls.CALENDARS}>My Calendars</Link>
                </li>
                <li className={classNames({ active: pathname.includes(CoachSettingsUrls.ZOOM_ACCOUNT) })}>
                    <Link to={CoachSettingsUrls.ZOOM_ACCOUNT}>My Zoom Account</Link>
                </li>
            </ul>
        </Sidebar>
    )
}

export default LeftSidebar
