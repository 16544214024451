import React, { ReactNode, Suspense, useCallback, useRef, useState } from "react"

import classNames from "classnames"
import { Link } from "react-router-dom"

import { useOnClickOutside } from "common/hooks/use-outside-click"
import { type IUseTranslation, useTranslation } from "common/hooks/use-translation"

import "main-app/assets/coach.scss"
import { CARE_TEAM_EMAIL } from "main-app/constants"
import { useAuthContext } from "main-app/context/Auth"
import { useStoreContext } from "main-app/context/GlobalStore"
import { CoachCalendarStepUrls, CoachSettingsUrls } from "main-app/pages/coach-settings/constants"
import ProductTypeLogo from "main-app/shared/ProductTypeLogo"
import Avatar from "main-app/shared/avatar/Avatar"
import FullSpinner from "main-app/shared/spinner/FullSpinner"
import UserDefaultAvatar from "main-app/svgs/UserDefaultAvatar"
import useRoles from "main-app/utils/hooks/use-roles"

interface IProps {
    leftSidebar?: React.ComponentType<any> | JSX.Element | null
    headerTitle?: ReactNode
    nav?: React.ComponentType<any> | null
    wrapperExtraClass?: string
    showLogo?: boolean
    navItem?: React.ComponentType<any> | JSX.Element | null
    align?: "center" | "left"
    size?: "default" | "max-md"
    pageTitle?: ReactNode
    fullWidth?: boolean
    withContainer?: boolean
    children: ReactNode | ReactNode[]
}

const CoachLayout: React.FC<IProps> = ({
    children,
    leftSidebar,
    pageTitle = null,
    wrapperExtraClass = "",
    showLogo = true,
    align = "left",
    withContainer = true,
    headerTitle = null,
    nav = null,
    navItem = null,
    size = "default",
    fullWidth = false
}) => {
    const { t }: IUseTranslation = useTranslation()

    const { logout, user } = useAuthContext()
    const { isCoach } = useRoles()
    const { isOpenBurgerMenu, toggleBurgerMenu } = useStoreContext()
    const [isActiveMenu, setIsActiveMenu] = useState(false)
    const outsideRefClick = useRef<HTMLDivElement>()

    const toggleActiveMenu = () => setIsActiveMenu(prev => !prev)

    const handleLogout = useCallback(
        e => {
            e.preventDefault()
            logout()
        },
        [logout]
    )

    useOnClickOutside(outsideRefClick, () => setIsActiveMenu(false))

    return (
        // @ts-expect-error legacy markup defect
        <div className={classNames("coach-wrapper", { [wrapperExtraClass]: wrapperExtraClass })}>
            {nav || (
                <nav className="coach-navbar navbar navbar-expand-lg justify-content-between">
                    {/* @ts-expect-error legacy markup defect */}
                    <div className="d-flex align-items-center">
                        {showLogo && (
                            <Link to="/" className="d-block">
                                <ProductTypeLogo />
                            </Link>
                        )}
                        {navItem}
                    </div>
                    <div className="navbar-container-screen">
                        <div className="coach-nav-header-title">{headerTitle}</div>
                        <div
                            id="navbar"
                            className={classNames("collapse navbar-collapse ml-lg-auto", {
                                show: isOpenBurgerMenu
                            })}>
                            <div className="header-nav-item header-avatar-item d-none d-lg-block position-relative">
                                <div className="dropdown header-nav-dropdown">
                                    <button
                                        className="btn dropdown-toggle d-lg-inline-flex m-0"
                                        type="button"
                                        id="userDropdownButton"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={toggleActiveMenu}>
                                        {user?.photo ? (
                                            <Avatar
                                                width={30}
                                                height={30}
                                                url={user?.photo}
                                                alt={`photo ${user?.firstName}`}
                                            />
                                        ) : (
                                            <span className="image image--26 image--rounded d-none d-lg-inline-flex">
                                                <UserDefaultAvatar />
                                            </span>
                                        )}
                                    </button>
                                </div>
                                <div
                                    className={classNames("dropdown-header-menu secondary", { show: isActiveMenu })}
                                    ref={outsideRefClick}>
                                    <a className="dropdown-header-item secondary" href={CARE_TEAM_EMAIL}>
                                        Contact Care Team
                                    </a>
                                    {isCoach && (
                                        <Link
                                            className="dropdown-header-item secondary"
                                            to={
                                                user.calendar_setup_confirmed
                                                    ? CoachSettingsUrls.AVAILABILITY
                                                    : CoachCalendarStepUrls.LINK_CALENDAR_STEP
                                            }>
                                            Calendar Settings
                                        </Link>
                                    )}
                                    <span className="dropdown-header-item secondary" onClick={handleLogout}>
                                        {t("Sign Out")}
                                    </span>
                                </div>
                            </div>
                            <div className="header-nav-item d-lg-none">
                                <span className="header-nav-link active">Participant List</span>
                            </div>
                            <div className="header-nav-item d-lg-none">
                                <a href={CARE_TEAM_EMAIL} className="header-nav-link">
                                    Contact Care Team
                                </a>
                            </div>
                            <div className="header-nav-item d-lg-none">
                                <span className="header-nav-link" onClick={handleLogout}>
                                    {t("Sign Out")}
                                </span>
                            </div>
                        </div>
                    </div>
                    <button
                        className={classNames("navbar-toggler hamburger hamburger--squeeze")}
                        type="button"
                        data-toggle="collapse"
                        onClick={toggleBurgerMenu}
                        data-target="#navbar"
                        aria-controls="navbarTogglerDemo01"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="hamburger-box">
                            <span className="hamburger-inner" />
                        </span>
                    </button>
                </nav>
            )}

            {leftSidebar}

            <main
                className={classNames("coach-main", {
                    "w-100": !leftSidebar,
                    "coach-main-s": !fullWidth && !!leftSidebar,
                    "with-aside-margin": fullWidth && !!leftSidebar
                })}>
                <div
                    className={classNames({
                        "container-max-md": size === "max-md",
                        container: withContainer && align === "left" && (!fullWidth || (leftSidebar && fullWidth)),
                        "container-center": align === "center"
                    })}>
                    {pageTitle && (
                        <div className="page-title">
                            <h1>{pageTitle}</h1>
                        </div>
                    )}
                    <Suspense fallback={<FullSpinner />}>{children}</Suspense>
                </div>

                <footer className="footer" />
            </main>
        </div>
    )
}

export default CoachLayout
